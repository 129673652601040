<template>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8 col-xl-6">
      <div v-if="success" class="card">
        <div class="card-body text-center">
          <p><img width="150" src="@/assets/images/usercreated.svg"></p>
          <h1>We’re thrilled to have you on board!</h1>
          <p>An email is on its way to verify your email address <strong>{{ form.getFieldValue('email') }}</strong>. Please check your inbox and click the link contained in the email.</p>
        </div>
      </div>
      <a-form v-else :form="form" @submit.prevent.native="onSubmit">
        <div class="card">
          <div class="card-header">
            <strong>Create Account</strong>
          </div>
          <div class="card-body">
            <h2><i class="fad fa-fw fa-key fa-sm text-primary"></i> New Credentials</h2>
            <a-form-item label="Email Address" :colon="false" required>
              <a-input
                v-decorator="['email', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Email address is required.' }],
                  }]
                }]"
                name="email"
                type="email"
                placeholder="name@company.com"
              />
            </a-form-item>
            <a-form-item label="Secure Password" :colon="false" required>
              <a-input
                v-decorator="['password', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Password is required.' }],
                  }, {
                    trigger: 'blur',
                    rules: [{ min: 6, message: 'Password is too short.' }],
                  }]
                }]"
                name="password"
                type="password"
                placeholder="Choose a secure password"
              />
            </a-form-item>
            <hr>
            <h2><i class="fad fa-fw fa-user-circle fa-sm text-primary"></i> Account Information</h2>
            <a-form-item label="Business Name" :colon="false" required>
              <a-input
                v-decorator="['name', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Name is required.' }],
                  }]
                }]"
                name="name"
              />
            </a-form-item>
            <a-form-item label="Country" :colon="false" required>
              <a-select
                v-decorator="['country_code', {
                  validate: [{
                    trigger: 'change',
                    rules: [{ required: true, message: 'Country is required.' }],
                  }]
                }]"
                name="country_code"
                placeholder="Select a country"
                showSearch
                :filterOption="filterOption"
              >
                <a-select-option v-for="(name, code) in $store.state.countries" :key="code" :value="code">{{ name }}</a-select-option>
              </a-select>
            </a-form-item>
            <small>By signing up you agree to have read and understood the <a href="https://vatstack.com/terms" target="_blank">Terms and Conditions</a>.</small>
          </div>
          <div class="card-footer">
            <a-button type="primary" html-type="submit" :loading="loading">
              Register
            </a-button>
            <a-button type="link" @click="$router.push({ name: 'login' })">
              Already have an account?
            </a-button>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      success: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this)
  },

  methods: {
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          await this.$httpd.post(`/user`, values)

          this.success = true
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
