var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-lg-8 col-xl-6"},[(_vm.success)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body text-center"},[_vm._m(0),_c('h1',[_vm._v("We’re thrilled to have you on board!")]),_c('p',[_vm._v("An email is on its way to verify your email address "),_c('strong',[_vm._v(_vm._s(_vm.form.getFieldValue('email')))]),_vm._v(". Please check your inbox and click the link contained in the email.")])])]):_c('a-form',{attrs:{"form":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('strong',[_vm._v("Create Account")])]),_c('div',{staticClass:"card-body"},[_c('h2',[_c('i',{staticClass:"fad fa-fw fa-key fa-sm text-primary"}),_vm._v(" New Credentials")]),_c('a-form-item',{attrs:{"label":"Email Address","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Email address is required.' }],
                }]
              }]),expression:"['email', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Email address is required.' }],\n                }]\n              }]"}],attrs:{"name":"email","type":"email","placeholder":"name@company.com"}})],1),_c('a-form-item',{attrs:{"label":"Secure Password","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Password is required.' }],
                }, {
                  trigger: 'blur',
                  rules: [{ min: 6, message: 'Password is too short.' }],
                }]
              }]),expression:"['password', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Password is required.' }],\n                }, {\n                  trigger: 'blur',\n                  rules: [{ min: 6, message: 'Password is too short.' }],\n                }]\n              }]"}],attrs:{"name":"password","type":"password","placeholder":"Choose a secure password"}})],1),_c('hr'),_c('h2',[_c('i',{staticClass:"fad fa-fw fa-user-circle fa-sm text-primary"}),_vm._v(" Account Information")]),_c('a-form-item',{attrs:{"label":"Business Name","colon":false,"required":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Name is required.' }],
                }]
              }]),expression:"['name', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Name is required.' }],\n                }]\n              }]"}],attrs:{"name":"name"}})],1),_c('a-form-item',{attrs:{"label":"Country","colon":false,"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country_code', {
                validate: [{
                  trigger: 'change',
                  rules: [{ required: true, message: 'Country is required.' }],
                }]
              }]),expression:"['country_code', {\n                validate: [{\n                  trigger: 'change',\n                  rules: [{ required: true, message: 'Country is required.' }],\n                }]\n              }]"}],attrs:{"name":"country_code","placeholder":"Select a country","showSearch":"","filterOption":_vm.filterOption}},_vm._l((_vm.$store.state.countries),function(name,code){return _c('a-select-option',{key:code,attrs:{"value":code}},[_vm._v(_vm._s(name))])}),1)],1),_c('small',[_vm._v("By signing up you agree to have read and understood the "),_c('a',{attrs:{"href":"https://vatstack.com/terms","target":"_blank"}},[_vm._v("Terms and Conditions")]),_vm._v(".")])],1),_c('div',{staticClass:"card-footer"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Register ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v(" Already have an account? ")])],1)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"width":"150","src":require("@/assets/images/usercreated.svg")}})])
}]

export { render, staticRenderFns }